import React, { useState }  from "react"
import './LicenseProfile.scss'
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import LicenseDocument from "./LicenseDocument"

function LicenseProfile({title, date, html, licenses, currentSlug}){
    const { t } = useTranslation()
    
    return(
        <section className="license">
            <div className="license__header">
                <h2>{t("licenses.title")}</h2>
            </div>
            <div className="license__content__wrapper">
                <div className="left">
                    {licenses.map((license)=>{
                        const handleClick = () => {    
                            if(license.fields.slug === currentSlug){
                                return "active"
                            }
                            else{
                                return "" 
                            }
                          }
                        return(
                         <Link 
                          className={`btn ${handleClick()}`}
                          to={license.fields.slug}
                          key={license.id}
                          >{license.frontmatter.date}</Link>
                        )
                    })}
                </div>
                <LicenseDocument
                    title={title}
                    date={date}
                    html={html}
                /> 
            </div>
        </section>
    )
}

export default LicenseProfile
