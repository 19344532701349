import React  from "react"
import './LicenseProfile.scss'
import { useTranslation } from "react-i18next"

function LicenseDocument({title, date, html}){
    return(
        <div className="right">
            <h1>{title}</h1>
            <small>{date}</small>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    )
}

export default LicenseDocument


