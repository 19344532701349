import React from "react"

import Layout from "../components/layout"
import Head from "../components/Head"
import { graphql } from "gatsby"
import LicenseProfile from "../components/clients/licenseProfile/LicenseProfile"

export default function IndividualLicensePage({ data }) {
  const post = data.markdownRemark
  const slug = post.fields.slug
  const date = post.frontmatter.date
  const country = post.frontmatter.country
  
  let hotLine
  if(country.startsWith("lt_")){
    hotLine = data.site.siteMetadata.countries['lt']['hotLine']
  }else{
    hotLine = data.site.siteMetadata.countries[country]['hotLine']
  }
  
  const {licenses}=data.license
  const licensesByCountry = licenses.filter((license)=> license.frontmatter.country === country && license.frontmatter.subcategory === "individual_license").reverse()

  return (
    <Layout
      country={country}
      hotLine={hotLine}
    >
      <Head
        lang={country}
        title={"Лицензия Allsports " + date}
        slug={post.fields.slug}
      />
      <LicenseProfile 
        title={post.frontmatter.title}
        datee={date}
        html={post.html}
        currentSlug={slug}
        licenses = {licensesByCountry}
      />
    </Layout>
  )
}

export const query = graphql`
  query IndividualLicenseQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        country
      }
    }
    license: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(individual_license)/" } }
    ) {
      licenses: nodes {
        fields {
          slug
        }
        frontmatter {
          title
          country
          subcategory
          date(formatString: "DD.MM.YYYY")
        }
        id
      }
    }
    site {
      siteMetadata {
        senderPath
        countries {
          by {
            hotLine {
              tel
              title
            }
          }
          am {
            hotLine {
              tel
              title
            }
          }
          ru {
            hotLine {
              tel
              title
            }
          }
        }
      }
    }
  }
`
